import {
  POWER_SCHEDULES,
  POWER_TYPES,
  ROUTER_STATUSES,
} from "@/components/constants.js"

export default (self) => {
  return {
    number: {
      component: "TextFieldSearchFilter",
    },
    simProvider: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...self.simProviderList],
      },
    },
    billPayer: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...self.billPayerList],
      },
    },
    iccid: {
      component: "TextFieldSearchFilter",
      name: "iccid",
    },
    cameras: {
      component: "TextFieldSearchFilter",
      name: "cameraName",
    },
    routerType: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...self.routerTypes],
        value: "",
      },
    },
    serialNumber: {
      component: "TextFieldSearchFilter",
      name: "serialNumber",
    },
    description: {
      component: "TextFieldSearchFilter",
      name: "description",
    },
    status: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...ROUTER_STATUSES],
        value: "",
      },
    },
    powerType: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...POWER_TYPES],
        value: "",
      },
    },
    powerSchedule: {
      component: "SelectSearchFilter",
      attributes: {
        items: [{ value: "", name: "All" }, ...POWER_SCHEDULES],
        value: "",
      },
    },
  }
}
